import React from "react"
import ContactCard from "./contactCard"

export default function ContactSection() {
  return (
    <div className="mt-24 mb-40" id="contact">
      <ContactCard onSubmit={() => {}} />
    </div>
  )
}
