import React from "react"
import { string, object } from "prop-types"

import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const BlogItemPreview = ({
  frontmatter: { title, slug, description },
  publishedAt,
  image,
  coverImageAlt,
}) => {
  return (
    <article className="w-64 flex flex-col px-2 justify-between mx-auto">
      <div className="flex flex-col">
        <Link to={`blog/${slug}`}>
          <GatsbyImage image={image} alt={coverImageAlt} />
        </Link>
        <h3 className="font-sans font-semibold text-gray-700  dark:text-gray-300 leading-tight mb-2 mt-2">
          {title}
        </h3>
        <sub className="mb-6 font-sans text-gray-900 dark:text-gray-500">
          Published at: {publishedAt}
        </sub>
        <p className="font-sans leading-tight text-gray-800 dark:text-gray-400 tracking-tight">
          {description}
        </p>
      </div>
      <Link
        className="font-sans mt-3 font-semibold leading-tight text-gray-800 dark:text-gray-400 tracking-tight"
        to={`blog/${slug}`}
        onClick={() => {
          window.plausible("blogClick", { props: { slug } })
        }}
      >
        Read more&gt;
      </Link>
    </article>
  )
}

BlogItemPreview.prototypes = {
  title: string,
  slug: string,
  coverImageAlt: string,
  excerpt: string,
  publishedAt: string,
  image: object,
}

export default BlogItemPreview
