import React, { useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import CTACard from "./ctaCard"

export default function AboutMeSection() {
  const ref = useRef(null)
  const [paddingTop, setPaddingTop] = useState("40px")

  useEffect(() => {
    setPaddingTop(window.getComputedStyle(ref.current).paddingTop)
  }, [])

  useEffect(() => {
    const handler = () => {
      const newPadding = window.getComputedStyle(ref.current).paddingTop
      if (newPadding !== paddingTop) {
        setPaddingTop(newPadding)
      }
    }

    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [paddingTop])
  return (
    <div
      className="pt-24 md:pt-96 bg-purple-500 dark:bg-darkBg mx-10"
      ref={ref}
    >
      <CTACard paddingTop={paddingTop} />
      <div className="flex flex-col justify-center items-center" id="about">
        <StaticImage
          src="../images/dennis_crop.jpg"
          placeholder="blurred"
          layout="fixed"
          width={240}
          height={240}
          alt="A portrait of Dennis Simon"
          className="rounded-full mt-28 md:mt-64 inline-block grow-0 shrink-0"
        />
        <div className="text-xl max-w-2xl leading-6 text-center mt-8 text-gray-800 dark:text-gray-300 font-regular">
          <h2 className="font-extrabold text-3xl md:text-5xl mb-4 text-gray-800 dark:text-gray-300 ">
            Hi there!
          </h2>
          <br />
          <br />
          <p>I'm glad you made it here.</p>
          <br />
          <br />

          <p>
            My name is Dennis and i'm a passionate full-stack engineer from
            Munich, Germany.
          </p>
          <br />
          <br />
          <p>
            When i'm not coding you will either find me gaming, travelling or
            eating (i'm a biiiig foodie). At my previous workplace,{" "}
            <a href="https://steadforce.com" className="font-semibold">
              steadforce
            </a>
            , i had the opportunity to work with big international clients,
            tackling big and interesting problems. If you are interested in the
            technologies i like to use, check the list below.
          </p>
          <br />
          <p className="text-sm">
            P.S. I also (try to condition myself to) blog regularly, have a look
            at my posts at the bottom
          </p>
        </div>
      </div>
    </div>
  )
}
