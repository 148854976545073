import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

const defaultLabelClasses =
  "block uppercase tracking-wide text-gray-700 dark:text-gray-400 text-xs font-bold mb-2 mt-4"
const defaultInputClasses =
  "appearance-none block w-full bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-400 text-xs border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"

const makeCallback = (valueSetter, validitySetter) => e => {
  valueSetter(e.currentTarget.value)
  validitySetter(e.currentTarget.validity.valid)
}

const ContactCard = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("")
  const [firstNameValid, setFirstNameValid] = useState(true)
  const [lastName, setLastName] = useState("")
  const [lastNameValid, setLastNameValid] = useState(true)
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(true)
  const [message, setMessage] = useState("")
  const [messageValid, setMessageValid] = useState(true)

  const allValid = useMemo(
    () => firstNameValid && lastNameValid && emailValid && messageValid,
    [firstNameValid, lastNameValid, emailValid, messageValid]
  )

  const allHaveValue = useMemo(
    () => firstName && lastName && email && message,
    [firstName, lastName, email, message]
  )

  return (
    <div className="md:card dark:bg-gray-800/60 flex justify-center items-center flex-col font-sans max-w-5xl md:py-11 px-4 sm:px-10 md:px-32 lg:px-56 m-auto shadow-black/90">
      <span className="font-bold text-3xl md:text-5xl inline-block mx-2 text-gray-900 dark:text-gray-300">
        Shoot me a message
      </span>
      <form
        className="w-full flex flex-col justify-center"
        name="contact"
        netlify
        action="/thanks"
        method="POST"
        netlify-honeypot="message-2"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="grid grid-cols-2 gap-2 w-full mt-10">
          <div>
            <label className={defaultLabelClasses} htmlFor="first-name">
              First Name*
            </label>
            <input
              className={defaultInputClasses}
              id="first-name"
              name="first-name"
              type="text"
              placeholder="First name"
              required
              onChange={makeCallback(setFirstName, setFirstNameValid)}
            />
            <p
              className={`text-red-500 text-xs italic ${
                firstNameValid ? "hidden" : ""
              }`}
            >
              This field is required
            </p>
          </div>
          <div>
            <label className={defaultLabelClasses} htmlFor="last-name">
              Last Name*
            </label>
            <input
              className={defaultInputClasses}
              id="last-name"
              name="last-name"
              type="text"
              placeholder="Last name"
              required
              onChange={makeCallback(setLastName, setLastNameValid)}
            />
            <p
              className={`text-red-500 text-xs italic ${
                lastNameValid ? "hidden" : ""
              }`}
            >
              This field is required
            </p>
          </div>
          <div className="col-span-2">
            <label className={defaultLabelClasses} htmlFor="email">
              Email*
            </label>
            <input
              className={defaultInputClasses}
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={makeCallback(setEmail, setEmailValid)}
            />
            <p
              className={`text-red-500 text-xs italic ${
                emailValid ? "hidden" : ""
              }`}
            >
              Must be a valid email address
            </p>
          </div>
          <div className="col-span-2">
            <label className={defaultLabelClasses} htmlFor="message">
              Message*
            </label>
            <textarea
              className={`${defaultInputClasses} h-56`}
              id="message"
              name="message"
              placeholder="Your message"
              required
              onChange={makeCallback(setMessage, setMessageValid)}
            />
            <p
              className={`text-red-500 text-xs italic ${
                messageValid ? "hidden" : ""
              }`}
            >
              This field is required
            </p>
          </div>
          <div className="col-span-2 form-field-a">
            <label className={defaultLabelClasses} htmlFor="message-2">
              Message*
            </label>
            <textarea
              className={`${defaultInputClasses} h-56`}
              id="message-2"
              name="message-2"
              placeholder="Your message"
            />
            <p
              className={`text-red-500 text-xs italic ${
                messageValid ? "hidden" : ""
              }`}
            >
              This field is required
            </p>
          </div>
        </div>
        <button
          className="rounded px-16 py-4 bg-green-500 dark:bg-green-800 dark:disabled:bg-gray-600 shadow-md text-white dark:text-gray-300 font-sans font-bold leading-tight text-2xl mt-10 tracking-tight disabled:shadow-none disabled:bg-gray-400 disabled:text-gray-600 transition-all"
          disabled={!allValid || !allHaveValue}
          type="submit"
        >
          Let's work together
        </button>
      </form>
    </div>
  )
}

ContactCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ContactCard
