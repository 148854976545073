import * as React from "react"

import Layout from "@components/layout"
import Seo from "@components/seo"
import HeroSection from "@components/heroSection"
import AboutMeSection from "@components/aboutMeSection"
import SkillsSection from "@components/skillsSection"
import ContactSection from "@components/contactSection"
import BlogList from "@components/blogList"

const IndexPage = () => (
  <Layout isHomePage={true}>
    <Seo title="Dennis Simon | senior fullstack developer" />
    <div className="max-w-screen-xl mx-8 md:mx-20 lg:mx-32">
      <HeroSection />
    </div>
    <div className="bg-purple-500 dark:bg-darkBg px-0 md:px-20 lg:px-32">
      <div className="max-w-screen-xl m-auto">
        <AboutMeSection />
        <SkillsSection />
      </div>
    </div>
    <ContactSection />
    <BlogList />
  </Layout>
)

export default IndexPage
