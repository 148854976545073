import React from "react"

export default function HeroSection() {
  return (
    <div className="mb-64 md:mb-96 mx-0 md:mx-10">
      <div id="hero-text" className="mt-16 md:mt-40">
        <span className="font-sans font-bold text-4xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-black dark:to-white">
          OH, HEY THERE!
        </span>
        <br />
        <span className="font-sans font-bold text-8xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-black dark:to-white mt-3">
          I'M DENNIS
        </span>
      </div>
      <div
        id="hero-description"
        className="mt-11 font-sans font-medium text-2xl text-gray-800 dark:text-gray-300"
      >
        <span>
          I'm a senior fullstack developer currently working at{" "}
          <a className="text-purple-500" href="https://0x.org/">
            0x labs
          </a>{" "}
          where i'm
        </span>
        <br />
        <span>
          building the{" "}
          <a className="text-purple-500" href="https://0xtracker.com/">
            0x tracker
          </a>
        </span>
      </div>
    </div>
  )
}
