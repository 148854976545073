import React from "react"
import PropTypes from "prop-types"

const SkillsBadge = ({ component, onHover, idx, active }) => {
  return (
    <div
      onMouseOver={onHover}
      onFocus={onHover}
      id="skills"
      role="listitem"
      className={`rounded-full inline-block grow-0 shrink-0 border-4 border-purple-500 overflow-hidden dark:bg-purple-900 bg-purple-700 dark:border-darkBg relative has-tooltip p-2 sm:p-6 ${
        active ? "mr-2 ml-[-12px]" : "mx-[-12px]"
      } transition-all`}
      style={{
        zIndex: idx,
      }}
    >
      {component}
    </div>
  )
}

SkillsBadge.propTypes = {
  component: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  onHover: PropTypes.func.isRequired,
  idx: PropTypes.number,
}

export default SkillsBadge
