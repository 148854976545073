import { navigate } from "gatsby"
import { string } from "prop-types"
import React, { useEffect, useRef, useState } from "react"

const CTACard = ({ paddingTop }) => {
  const [height, setHeight] = useState(360)

  const ref = useRef(null)

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [])

  useEffect(() => {
    const handler = () => {
      const newHeight = ref.current.clientHeight
      if (height !== newHeight) {
        setHeight(newHeight)
      }
    }

    window.addEventListener("resize", handler)

    return () => {
      window.removeEventListener("resize", handler)
    }
  }, [height, ref])
  return (
    <div
      className=" bg-white dark:bg-[#40205E] card flex justify-center items-center flex-col font-sans max-w-5xl py-10 md:py-24 px-8 md:px-20 lg:px-24 xl:px-40 m-auto shadow-black"
      style={{
        marginTop: `calc((${paddingTop} + (${height}px / 2)) * -1)`,
      }}
      ref={ref}
    >
      <span className="font-bold text-3xl md:text-5xl inline-block mx-2 leading-tight tracking-tight text-gray-800 dark:text-gray-300">
        Already caught your attention?
      </span>
      <button
        className="rounded-lg px-3 md:px-16 py-4 dark:text-gray-300 dark:bg-darkBg bg-green-500 shadow-md text-white font-sans font-bold text-xl md:text-3xl mt-5 md:mt-16 w-full"
        onClick={() => {
          navigate("#contact")
        }}
      >
        Let's work together
      </button>
    </div>
  )
}

CTACard.propTypes = {
  paddingTop: string,
}

export default CTACard
