import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import SkillsBadge from "./skillsBadge"

const size = 120
const offset = 12
export default function SkillsSection() {
  const [active, setActive] = useState(null)

  const skills = [
    <SkillsBadge
      idx={1}
      key="react"
      active={active === "React"}
      onHover={() => {
        setActive("React")
      }}
      component={
        <StaticImage
          src={"../images/react.svg"}
          alt={"React.js"}
          placeholder="blurred"
          height={size - offset * 4}
          width={size - offset * 4}
          objectFit="contain"
          aspectRatio={1 / 1}
          transformOptions={{
            cropFocus: "center",
          }}
        />
      }
    />,
    <SkillsBadge
      idx={2}
      key="vue"
      onHover={() => {
        setActive("Vue")
      }}
      active={active === "Vue"}
      component={
        <StaticImage
          src={"../images/vue.svg"}
          alt={"Vue"}
          placeholder="blurred"
          height={size - offset * 4}
          width={size - offset * 4}
          objectFit="contain"
          aspectRatio={1 / 1}
          transformOptions={{
            cropFocus: "center",
          }}
        />
      }
    />,
    <SkillsBadge
      idx={3}
      key="node"
      onHover={() => {
        setActive("Node")
      }}
      active={active === "Node"}
      component={
        <StaticImage
          src={"../images/node.svg"}
          alt={"Node.js"}
          placeholder="blurred"
          height={size - offset * 4}
          width={size - offset * 4}
          objectFit="contain"
          aspectRatio={1 / 1}
          transformOptions={{
            cropFocus: "center",
          }}
        />
      }
    />,
    <SkillsBadge
      idx={4}
      key="go"
      onHover={() => {
        setActive("Go")
      }}
      active={active === "Go"}
      component={
        <StaticImage
          src={"../images/go.svg"}
          alt={"GoLang"}
          placeholder="blurred"
          height={size - offset * 4}
          width={size - offset * 4}
          objectFit="contain"
          aspectRatio={1 / 1}
          transformOptions={{
            cropFocus: "center",
          }}
        />
      }
    />,
  ]
  return (
    <div className="flex items-center align-center flex-col mt-28 pb-20 mx-10">
      <p className="font-sans font-semibold text-3xl md:text-5xl mb-8 text-gray-800 dark:text-gray-300">
        Technologies I regularly work with:
      </p>
      <br />
      <div>{skills}</div>
      <div className="font-sans font-semibold text-gray-800 dark:text-gray-300">
        {active}
      </div>
    </div>
  )
}
