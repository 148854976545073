import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { isProd, reduceBy } from "../utils/utils"
import moment from "moment"
import BlogItemPreview from "./blogItemPreview"

const query = graphql`
  {
    allMdx {
      nodes {
        id
        images {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 256, height: 200, placeholder: BLURRED)
          }
          imageMeta {
            alt
          }
        }
        frontmatter {
          description
          title
          slug
          publishedAt
          published
        }
      }
    }
  }
`

export default function BlogList() {
  const data = useStaticQuery(query)
  let items = data?.allMdx?.nodes?.filter(item =>
    isProd ? item.frontmatter.published : true
  )
  items = items.map(item => {
    const {
      frontmatter: { publishedAt, published },
      images,
    } = item
    const publishedAtMoment = moment(publishedAt)
    const reducedImages = reduceBy("name", images)
    const formatted = publishedAtMoment.format("Do MMM YYYY, h:mm a")
    return {
      ...item,
      publishedAt: formatted,
      published,
      coverImageAlt: reducedImages.header.imageMeta.alt,
      image: getImage(reducedImages.header),
    }
  })
  return (
    <section className="px-2 md:px-32 w-full mb-10 mx-auto" id="blog">
      <h3 className="font-bold text-3xl md:text-5xl inline-block mx-2 text-gray-900 dark:text-gray-300 mb-8">
        Check out my blog posts
      </h3>
      <div className=" grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 gap-y-8 mx-auto">
        {items.map(({ id, ...item }) => (
          <BlogItemPreview {...item} key={id} />
        ))}
      </div>
    </section>
  )
}
